.fixed-preview {
    position: sticky;
    top: 40px;
}

.space > button {
    margin: 2px;
}

.box {
    margin: 0px 50px;
}

.fullWS {
    white-space: pre;
}